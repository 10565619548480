<template>
  <div class="mainContainer">
    <div class="header">
      <img src="@/assets/images/Shield-user.svg" alt="" />
      <span>{{ lbl['about-privacy'] }}</span>
    </div>
    <div class="body" v-html="term"></div>
  </div>
</template>

<script>
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
export default {
  name: 'privacy',
  mixins: [Mixin],
  props: {
    term: {
      type: String,
    },
  },
  data() {
    return {
      mode: 'loading',
    }
  },
  created() {
    Account.bzbsAnalyticTracking(
      'privacy_page',
      'privacy',
      'view_privacy',
      'on view',
    )
  },
}
</script>

<style lang="scss" scoped>
.body {
  padding: 30px;
  font-size: 14px;
  color: #757575;
}
</style>
