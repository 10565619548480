<template>
  <div class="container-fluid newbg">
    <Header v-if="usercache" />
    <div v-else class="btnContainer">
      <button @click="$router.go(-1)" class="backbtn-about">
      <img src="@/assets/images/arrowBack.svg" alt="" />{{ lbl['about-back'] }}
    </button>
    </div>
    <div class="mx-auto mt-1 content-outer-container main-about">
      <Privacy v-if="mode == 'privacy'" :term="term" />
      <Contactus v-else-if="mode == 'contactus'" />
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import Header from '@/components/Layout/Header'
import Privacy from './component/privacy'
import Contactus from './component/contactus'
import Cache from '@/helper/Cache.js'
import BzbsApi from '@/core/Http/BzbsApi.js'
import Vue from 'vue'
import Locale from '@/helper/locale.js'
export default {
  name: 'about',
  components: {
    Header,
    Privacy,
    Contactus,
  },
  mixins: [Mixin],
  data() {
    return {
      usercache: null,
      locale: Locale,
      term: '',
    }
  },
  computed: {
    mode() {
      return this.$route.params.mode
    },
    currentLocale() {
      return Locale.getLocaleShort() || 'th'
    },
  },
  mounted() {
    this.handleFooter(true)
    this.usercache = Cache.get('CRMUser')
    this.getprivate()
  },
  methods: {
    getprivate() {
      var urlblob =
        Vue.bzbsConfig.bzbsBlobUrl +
        `/config/crmplus/terms-and-conditions/${this.currentLocale}.html`
      BzbsApi.connectBzbsGetBlob(urlblob).then(res => {
        this.term = res.data
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.container-fluid {
  // background-image: url('~@/assets/images/Revamp_bg.webp') !important;
  background-size: cover !important;
  background-attachment: fixed;
  background-position: center;
}
</style>
<style lang="scss">
.btnContainer{
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}
.backbtn-about {
  margin: 10px 0 10px 0px;
  border-radius: 6px;
  padding: 8px 16px;
  color: #757575;
  border: solid 1px #757575;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  img {
    margin-right: 8px;
    position: relative;
    top: -2px;
  }
}
.main-about {
  .mainContainer {
    width: 100%;
    min-height: calc(100vh - 160px);
    margin-bottom: 24px;
    .header {
      color: #212121;
      font-size: 34px;
      padding: 16px 30px;
      border-bottom: 1px solid #f5f5f5;
      span {
        margin-left: 16px;
      }
      img {
        position: relative;
        top: -5px;
      }
    }
    .body {
      padding: 30px;
    }
  }
}
</style>
