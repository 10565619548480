<template>
  <div class="mainContainer">
    <div class="header">
      <img src="@/assets/images/Chat2.svg" alt="" />
      <span>{{ lbl['about-contactus'] }}</span>
    </div>
    <div class="body">
      <div class="logo">
        <img
          src="@/assets/images/newlogocolored.svg"
          alt=""
          class="logo-image"
        />
      </div>
      <div class="addline">
        <div class="tltle">{{ lbl['about-contactus-addline'] }}</div>
        <div class="logo">
          <img src="@/assets/images/lineqrcodebzb.png" alt="" />
        </div>
        <div class="detail">
          {{ lbl['about-contactus-addlinetext'] }}
        </div>
        <button @click="goto">{{ lbl['about-contactus-line'] }}</button>
      </div>
      <div class="email lable">
        <img src="@/assets/images/Mail.svg" alt="" />
        <span>{{ lbl['about-contactus-mail'] }}</span>
      </div>
      <div class="tel lable">
        <img src="@/assets/images/Call.svg" alt="" />
        <span>{{ lbl['about-contactus-tel'] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
export default {
  name: 'contactus',
  mixins: [Mixin],
  data() {
    return {
      mode: 'loading',
    }
  },
  created() {
    Account.bzbsAnalyticTracking(
      'contact_us_page',
      'contact_us',
      'view_contact_us',
      'on view',
    )
  },
  methods: {
    goto() {
      Account.bzbsAnalyticTracking(
        'contact_us_page',
        'contact_us',
        'click_contact_line',
        'on click',
      )
      window
        .open(
          `https://line.me/R/ti/p/${this.lbl['about-contactus-line']}`,
          '_blank',
        )
        .focus()
    },
  },
}
</script>

<style lang="scss" scoped>
.logo-image {
  width: fit-content;
  height: fit-content;
}
.mainContainer {
  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .addline {
      margin-top: 16px;
      padding: 16px;
      width: 100%;
      max-width: 457px;
      border: solid 1px #e0e0e0;
      background-color: #fafafa;
      text-align: center;
      .tltle {
        font-size: 20px;
        font-weight: 600;
      }
      .detail {
        font-size: 14px;
        color: #757575;
        line-height: 1.57;
      }
      button {
        margin-top: 16px;
        padding: 8px;
        background-color: #4caf50;
        width: 100%;
        color: #ffffff;
        border-radius: 6px;
        border: none;
      }
    }
    .lable {
      margin-top: 16px;
      color: #616161;
      font-size: 20px;
      font-weight: 600;
      img {
        position: relative;
        top: -3px;
        margin-right: 8px;
      }
    }
  }
}
</style>
